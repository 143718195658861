import { HashLink } from 'react-router-hash-link';
import { useRef, useState } from 'react';
import '../styles/Footer.css';
import logoName from '../assets/logo_name.png';
import axios from 'axios';

const Footer = () => {
    const year = new Date().getFullYear();
    const formRef = useRef(null);
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post('https://datatactix-website-back-production.up.railway.app/newsletter', {email: email})
        .then((res) => {
            formRef.current.reset();
            setEmail('');
        })
        .catch((err) => {
            formRef.current.reset();
            setEmail('');
        });
    };

    return (
        <footer>
            <div className="footer-action">
                <div className="footer-newsletter">
                    <figure className="footer-logo">
                        <img src={logoName} alt="logo" />
                    </figure>
                    <p className="footer-newsletter-description">Suscríbete a nuestro boletín para recibir noticias y actualizaciones.</p>
                    <form ref={formRef} className="footer-newsletter-form" onSubmit={handleSubmit}>
                        <input type="email" placeholder="Correo electrónico" onChange={(e) => setEmail(e.target.value)} />
                        <button type="submit" className="btn-primary" disabled={email===''? true:false} >Suscribirse</button>
                    </form>
                    <p className="footer-newsletter-privacy">No compartiremos tu información con terceros. Al suscribirte, das tu consentimiento para recibir actualizaciones de nuestra empresa.</p>
                </div>
                <div className="footer-action-links" id='footer-action-primary-links'>
                    <h2>Enlaces</h2>
                    <HashLink to={'/#home-hero-section'} >Inicio</HashLink>
                    <HashLink to={'/#home-services-section'} >Servicios</HashLink>
                    <HashLink to={'/#home-solutions-section'} >Sobre Nosotros</HashLink>
                    <HashLink to={'/contacto/#contact-hero-section'} >Contáctanos</HashLink>
                </div>
                <div className="footer-action-links" id="footer-action-socials">
                    <h2>Síguenos</h2>
                    <HashLink to="https://www.facebook.com/datatactix.info?mibextid=ZbWKwL" target="_blank" rel="noreferrer">Facebook</HashLink>
                    {/* <HashLink to="https://www.instagram.com" target="_blank" rel="noreferrer">Instagram</HashLink>
                    <HashLink to="https://www.twitter.com" target="_blank" rel="noreferrer">Twitter</HashLink>
                    <HashLink to="https://www.linkedin.com" target="_blank" rel="noreferrer">LinkedIn</HashLink> */}
                </div>
            </div>
            <div className="footer-information">
                <p>&copy; {year} Datatactix. Todos los derechos reservados.</p>
                <div className="footer-information-links">
                    {/* <HashLink to={'/'} >Política de privacidad</HashLink>
                    <HashLink to={'/'} >Términos de uso</HashLink> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;