import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import Home from './views/Home';
import Contact from './views/Contact';
import logoOnly from './assets/logo_only.png';
import logoName from './assets/logo_name.png';

function App() {
  const [logoHover, setLogoHover] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isPhoneSize, setIsPhoneSize] = useState(window.innerWidth < 768);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const burgerDiv = useRef(null);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsPhoneSize(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleWindowClick = (e) => {
      if (burgerMenu && e.target.id !== 'burger-menu' && e.target.id !== 'burger-menu-list'
          && e.target.className !== 'burger-menu-link' && e.target.className !== 'burger-menu-links'
          && e.target.className !== 'nav-desktop'
      ) {
        setBurgerMenu(false);
      }
    }
    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, [burgerMenu]);

  return (
      <Router>
        <header className={scrolled || burgerMenu ? 'nav-scrolled' : ''}>
          <nav className='nav-desktop'>
            {(!isPhoneSize && (
              <div className="nav-links">
              <ul className="nav-list">
                <li className="nav-item"><HashLink to={'/#home-hero-section'} >Inicio</HashLink></li>
                <li className="nav-item"><HashLink to={'/#home-services-section'} >Servicios</HashLink></li>
                <li className="nav-item"><HashLink to={'/#home-solutions-section'} >Sobre Nosotros</HashLink></li>
              </ul>
            </div>
            )) || (isPhoneSize && (
              <p id='burger-menu' className='nav-links'
                onClick={() => {
                  if (burgerMenu) {
                    const burgerDivCurrent = burgerDiv.current;
                    burgerDivCurrent.classList.remove('burger-open');
                    burgerDivCurrent.classList.add('burger-close');
                    setTimeout(() => setBurgerMenu(false), 450);
                  }
                  else {
                    setBurgerMenu(prevState => !prevState);
                  }
                }}
              >&#8801;</p>
            ))}
            {isPhoneSize && burgerMenu && (
              <div ref={burgerDiv} id='burger-menu-list' className={burgerMenu ? 'burger-open' : ''}>
                <ul className='burger-menu-links'>
                  <li className="burger-menu-link"><HashLink to={'/#home-hero-section'} >Inicio</HashLink></li>
                  <li className="burger-menu-link"><HashLink to={'/#home-services-section'} >Servicios</HashLink></li>
                  <li className="burger-menu-link"><HashLink to={'/#home-solutions-section'} >Sobre Nosotros</HashLink></li>
                </ul>
              </div>
            )}
            <HashLink to={'/#home-hero-section'} className="nav-logo">
              <figure className='nav-logo' 
                    onMouseEnter={() => setLogoHover(true)} 
                    onMouseLeave={() => setLogoHover(false)}>
                <img src={logoHover ? logoName : logoOnly} alt="logo" />
              </figure>
            </HashLink>
            <HashLink to={'/contacto/#contact-hero-section'} id='nav-contact-btn' className="nav-btn btn-primary">¡Contáctanos!</HashLink>
          </nav>
        </header>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contacto' element={<Contact />} />
          <Route path='/servicios' element={<h1>Servicios</h1>} />
          <Route path='/sobre-nosotros' element={<h1>Sobre nosotros</h1>} />
        </Routes>
      </Router>
  );
}

export default App;
