import '../styles/Home.css';
import { servicesImages } from '../data/homeContent';
import { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { HashLink } from 'react-router-hash-link';

const Home = () => {
    const [servicesCounter, setServicesCounter] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setServicesCounter((prevCounter) => {
                if (prevCounter === 2) {
                    return 0;
                } else {
                    return prevCounter + 1;
                }
            });
        }, 3000);
        return () => clearInterval(interval);
    }, [servicesCounter]);
    return (
        <>
            <main>
                <section className="hero-section" id='home-hero-section'>
                    <article className="home-hero-content">
                        <h1>Soluciones de análisis de datos, desarrollo web, e infraestructura IT</h1>
                        <p>En <em>Datatactix</em> utilizamos soluciones de empresas grandes para negocios pequeños</p>
                        <div className="home-hero-btns">
                            <HashLink to={'/contacto/#contact-hero-section'} className="btn-primary">¡Contáctanos!</HashLink>
                            {/* <button className="btn-secondary">Más información</button> */}
                        </div>
                    </article>
                </section>
                <section id="home-services-section">
                    <article id="home-services-content">
                        <figure className="home-services-image">
                            <img src={servicesImages[servicesCounter].img} alt={servicesImages[servicesCounter].title} />
                        </figure>
                        <div id="home-services-overview">
                            {servicesImages.map((service) => {
                                return (
                                    <div key={service.id} className={`home-services-text ${servicesCounter === (service.id-1) ? 'services-overview-selected' : ''}`}>
                                        <h2>{service.title}</h2>
                                        <p>{service.description}</p>
                                    </div>
                                );})
                            }
                        </div>
                    </article>
                </section>
                <section id="home-solutions-section">
                    <article id="home-solutions-content">
                        <div className="home-solutions-text">
                            <div id="home-solutions-heading">
                                <h2>Experiencia, Tecnología Avanzada y Atención Personalizada</h2>
                                <p>Nuestro equipo altamente capacitado ofrece soluciones de desarrollo web, análisis de datos e infraestructura IT. Con tecnología avanzada y una atención personalizada, brindamos resultados excepcionales para nuestros clientes.</p>
                            </div>
                            <div id="home-solutions-perks">
                                <div className="home-solutions-perk">
                                    <h3>Experiencia</h3>
                                    <p>Nuestro equipo cuenta con años de experiencia en estos servicios y cuenta con gran preparación para ofrecer las mejores soluciones.</p>
                                </div>
                                <div className="home-solutions-perk">
                                    <h3>Tecnología</h3>
                                    <p>Utilizamos tecnología avanzada para ofrecer soluciones innovadoras y eficientes a nuestros clientes.</p>
                                </div>
                            </div>
                            <div id="home-solutions-btns">
                                <HashLink to={'/contacto/#contact-hero-section'} className="btn-primary">¡Contáctanos!</HashLink>
                                {/* <button className="btn-secondary">Más información</button> */}
                            </div>
                        </div>
                        <figure className="home-solutions-image">
                            <img src="https://dpbnri2zg3lc2.cloudfront.net/en/wp-content/uploads/2019/12/data-analyst-colleagues-in-office.jpeg" alt="Equipo de trabajo" />
                        </figure>
                    </article>
                </section>
                <section id="home-contact-section">
                    <article id="home-contact-content">
                        <h2>¡Contáctanos para una consulta gratuita!</h2>
                        <div id="home-contact-information">
                            <p>Descubre cómo podemos ayudarte con tus necesidades de desarrollo web, análisis de datos e infraestructura IT.</p>
                            <HashLink to={'/contacto/#contact-hero-section'} className="btn-primary">¡Contáctanos!</HashLink>
                        </div>
                    </article>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Home;