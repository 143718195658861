import '../styles/Contact.css';
import Footer from '../components/Footer';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const Contact = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [submit, setSubmit] = useState(false);
    const formRef = useRef(null);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    useEffect(() => {
        setSubmit(validateEmail(formData.email) && formData.name.length > 0 && formData.message.length > 0);
    }, [formData]);

    const handleSubmit = async (e) => {
        const form = formRef.current;
        e.preventDefault();
        if (submit) {
            try {
                await axios.post('https://datatactix-website-back-production.up.railway.app/contact', {
                    name: formData.name,
                    email: formData.email,
                    message: formData.message
                });
                form.reset();
                setFormData({ name: '', email: '', message: '' });
            } catch (error) {
                form.reset();
                setFormData({ name: '', email: '', message: '' });
            }
        }
        setSubmit(false);
    };
    return (
        <>
            <main>
                <section className="hero-section" id="contact-hero-section">
                    <article className="contact-hero-content">
                        <h1>Contacta con nosotros!</h1>
                        <p>Si tienes alguna pregunta o necesitas más información, estaremos encantados de ayudarte.</p>
                    </article>
                </section>
                <section id="contact-form-section">
                    <figure className="contact-form-image">
                        <img src="https://img.freepik.com/free-photo/man-climbing-stairs-heaven_1154-38.jpg?t=st=1718347065~exp=1718350665~hmac=df1118a5108eeecc4022f5b8816aab97c1e53c1fbafac561b0d349ddee2b6949&w=740" alt="man standing on stairs" />
                    </figure>
                    <div className="form-info">
                        <h2>¡Contáctanos!</h2>
                        <p>Completa el formulario a continuación y da el primer paso al futuro de tu negocio.</p>
                        <form ref={formRef} className="contact-form" onSubmit={handleSubmit} >
                            <label htmlFor="name">Nombre</label>
                            <input type="text" id="name" name="name" onChange={(e) => setFormData({...formData, name: e.target.value})} />
                            <label htmlFor="email">Correo Electrónico</label>
                            <input type="email" id="email" name="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                            <label htmlFor="message">Mensaje</label>
                            <textarea id="message" name="message" onChange={(e) => setFormData({...formData, message: e.target.value})} required></textarea>
                            <button type="submit" className={`btn-primary ${submit ? '' : 'btn-disabled'}`} disabled={!submit} >Enviar</button>
                        </form>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Contact;
